import React from "react";
import { useMutation, gql } from "@apollo/client";
import Form from "./UIL/Forms";
import { contactFormFields } from "../config/ContactForm.data";
import { triggerContactFormSubmitted } from "../analytics-helpers";

const SUBMIT_CONTACT_FORM = gql`
  mutation ContactForm($body: JSON) {
    contactForm(body: $body) {
      sent
    }
  }
`;

export default function ContactForm() {
  const [submitContactForm] = useMutation(SUBMIT_CONTACT_FORM);

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Get in touch
            </h1>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Contact Lingua Fonica with any questions about lessons or
              tutoring.
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <Form
              fields={contactFormFields.fields}
              successMessage={contactFormFields.successMessage}
              onSubmit={(values) => {
                triggerContactFormSubmitted(
                  values.Language,
                  values["What is your current language ability?"]
                );
                return submitContactForm({ variables: { body: values } });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
