import * as React from "react";
import ContactForm from "../components/ContactForm";

import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function ContactUsPage() {
  const seo = createSeoData({
    title: "Contact Us!",
    description:
      "Have a question? Get in contact with the Lingua Fonica team! We are happy to help.",
  });

  return (
    <Layout seo={seo}>
      <ContactForm />
    </Layout>
  );
}
